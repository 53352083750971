if (document.getElementById("mobile-usp-band")) {
	const uspBandBlock = {
		eleToGet: "",
		eleWidth: "",
		on: false,
		uspCar: document.querySelector(".usp-car"),
		slider: document.querySelector(".mobile-slider"),
		liEles: document.querySelectorAll(".mobile-slides"),
		liLength: null,
		clearInt: null,
		clearTime: null,
		evtList: null,
		init: function() {
			//this.on = true;
			//console.log('called');
			var self = this;
			this.eleToGet = document.querySelector(".page-header");
			this.eleWidth = this.eleToGet.offsetWidth;
			const lis = Array.prototype.slice.call(this.liEles);
			const theLength = lis.length;
			//this.translateBy = this.liLength;

			lis.forEach((li, index) => {
				const pixels = this.eleWidth + "px";
				li.style.width = pixels;
			});

			this.clearInt = setInterval(() => {
				const translate = (1 / theLength) * 100;
				self.slider.style.transform = `translate(-${translate}%)`;
			}, 4000);

			this.evtList = this.slider.addEventListener("transitionend", () => {
				this.slider.appendChild(this.slider.firstElementChild);
				this.slider.style.transition = "none";
				this.slider.style.transform = "translate(0)";
				this.clearTime = setTimeout(() => {
					self.slider.style.transition = "all 0.5s";
				});
			});
		}
	};

	window.addEventListener("DOMContentLoaded", () => {
		uspBandBlock.init();
	});
}
